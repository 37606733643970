@use '@angular/material' as mat;
@import "styles/variables";
@import 'bootstrap-variables';
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import '~bootstrap-sass/assets/stylesheets/_bootstrap';
@import '~quill-mention/dist/quill.mention.min.css';

@include mat.core();
@include mat.all-component-themes($app-theme);

// Load material icons
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

*, *:focus {
  outline: none !important;
}

// Some default styles...
html, body {
  margin: 0;
  padding: 0;
  font-family: $font;
  color: $primary-darkest;
  background-color: mat.get-color-from-palette($app-primary, 50);
  position: relative;
}

body {
  &.tour-active {
    pointer-events: none;
    overflow: hidden;
  }
}

[hidden] {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.justify-content-sb {
  justify-content: space-between;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.mt-2 {
  margin-top: 2px;
}

.w-100 {
  width: 100% !important;
}

.w-20-p {
  width: 20%;
}

.w-30-p {
  width: 30%;
}

.w-15-p {
  width: 15%;
}

.margin-0 {
  margin: 0;
}

.margin-l-10 {
  margin-left: 10px;
}

.margin-l-5 {
  margin-left: 5px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.resize-none {
  resize: none !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-half {
  opacity: .5;
}

.margin-b-15 {
  margin-bottom: 15px;
}

.close-icon {
  border-radius: 50% !important;
  border: 1px solid #607d8b !important;
  background: #607d8b !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 14px !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: 6px !important;
}

.unseen-badge {
  align-items: center;
  background: mat.get-color-from-palette($app-warn);
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  margin-left: .5em;
  width: 20px;
}

.undecided-badge {
  align-items: center;
  background: mat.get-color-from-palette($app-primary, 100, .8);
  border-radius: 10px;
  display: inline-flex;
  font-size: 12px;
  height: 20px;
  padding: 0 .5em;
}

.snackbar-error {
  background: mat.get-color-from-palette($app-warn);
  color: rgba(#fff, .85);
  font-weight: bold;
}

.ql-tooltip {
  z-index: 2;
}

.warning {
  align-items: center;
  color: #ff9832;
  display: flex;

  mat-icon {
    margin-right: .4em;
  }
}

.mat-dialog-container {
  max-height: 90vh !important;
}

.ql-editor {
  h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  h1, h2, h3 {
    margin-top: 20px;
  }

  h4, h5, h6 {
    margin-top: 10px;
  }

  h1 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 18px !important;
  }

  h5 {
    font-size: 14px !important;
  }

  h6 {
    font-size: 12px !important;
  }

  p {
    margin-bottom: .5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.atmention {
  background: rgba(0, 0, 0, .1);
  border-radius: 3px;
  font-weight: 500;
  padding: 2px 3px;

  &__additional {
    font-size: .9em;
    font-weight: 400;
  }

  &--loading {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeload;
    animation-timing-function: linear;
    background: linear-gradient(to right, rgba(0, 0, 0, .02) 0%, rgba(0, 0, 0, .1) 40%, rgba(0, 0, 0, .02) 80%);
    background-size: 50px 23px;
    display: inline-block;
    height: 23px;
    margin: -8px 0 -1px;
    position: relative;
    top: 5px;
    width: 50px;
  }
}

@keyframes placeload {
  0% {
    background-position: -25px 0;
  }
  100% {
    background-position: 25px 0;
  }
}

a[mat-button],
a.mat-button-base {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.mat-checkbox-label {
  font-weight: 400;
}

img {
  max-width: 100%;
}

pdf-viewer {
  display: block;
  position: relative;
}
