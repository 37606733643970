@use '@angular/material' as mat;

$font: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$ap-software-primary: (
  50: #e7eff6,
  100: #c2d7e9,
  200: #99bddb,
  300: #70a2cd,
  400: #528ec2,
  500: #337ab7,
  600: #2e72b0,
  700: #2767a7,
  800: #205d9f,
  900: #144a90,
  A100: #c4dcff,
  A200: #91bdff,
  A400: #5e9eff,
  A700: #458fff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$app-primary: mat.define-palette(mat.$blue-gray-palette);
$app-accent:  mat.define-palette($ap-software-primary);
$app-warn:    mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

$primary-darkest:  mat.get-color-from-palette($app-primary, 900);
$primary-darker:   mat.get-color-from-palette($app-primary, 800);
$primary-dark:     mat.get-color-from-palette($app-primary, 600);
$primary-medium:   mat.get-color-from-palette($app-primary, 400);
$primary-light:    mat.get-color-from-palette($app-primary, 200);
$primary-lighter:  mat.get-color-from-palette($app-primary, 50);
$primary-lightest: #fff;

$header__height: 50px;
$footer__height: 50px;

$priority1: rgb(173, 216, 230);
$priority2: rgb(144, 238, 144);
$priority3: rgb(255, 165, 0);
$priority4: rgb(255, 0, 0);
